<template>
  <main>
    <main
      v-if="type === 'month'"
      style="z-index: 999"
      class="bg-white rounded-xl p-2 grid grid-cols-2 gap-2 w-72 items-center justify-between"
    >
      <div
        v-for="month in months"
        :key="month"
        @click="updateValue(month)"
        class="border p-2 w-full rounded-lg cursor-pointer border-outlineGray text-center text-sm text-newGray"
      >
        {{ month }}
      </div>
    </main>

    <main
      v-else
      style="z-index: 999"
      class="bg-white rounded-xl p-2 grid grid-cols-2 gap-2 w-72 items-center justify-between"
    >
      <div v-show="showOpts" @click="showOpts = false" class="cursor-pointer col-span-2">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="8" fill="#F2F1F1" />
          <path
            d="M17 12L7 12M7 12L11.5 16.5M7 12L11.5 7.5"
            stroke="#11453B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        v-for="range in Object.keys(result)"
        :key="range"
        @click="switchToOptions(range)"
        class="border p-2 w-full rounded-lg cursor-pointer border-outlineGray text-center text-sm text-newGray"
        v-show="!showOpts"
      >
        {{ range }}
      </div>

      <div
        v-for="year in years"
        :key="year"
        @click="updateValue(year)"
        class="border p-2 w-full rounded-lg cursor-pointer border-outlineGray text-center text-sm text-newGray"
        v-show="showOpts"
      >
        {{ year }}
      </div>
    </main>

    <div
      @click="closeMe"
      :class="!isVisible ? 'pointer-events-none' : undefined"
      class="bg-transparent fixed inset-0"
      style="z-index: -60"
    ></div>
  </main>
</template>

<script setup>
import { ref } from "vue";

const emit = defineEmits(["update", "close"]);
const props = defineProps({
  type: {
    type: String,
    default: "",
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

function updateValue(val) {
  let value;
  if (props.type === "month") {
    value = months.value.indexOf(val);
  } else {
    value = val;
  }
  emit("update", value);
}

function groupYears() {
  const currentYear = new Date().getFullYear();
  const startYear = 1940;

  let yearRanges = {};

  for (let year = startYear; year <= currentYear; year += 10) {
    const rangeStart = year;
    const rangeEnd = year + 9 > currentYear ? currentYear : year + 9;

    const rangeKey = `${rangeStart}-${rangeEnd}`;
    yearRanges[rangeKey] = [];

    for (let i = rangeStart; i <= rangeEnd; i++) {
      yearRanges[rangeKey].push(i);
    }
  }

  return yearRanges;
}

const result = groupYears();

const years = ref([]);
const showOpts = ref(false);

function switchToOptions(rng) {
  showOpts.value = true;
  years.value = result[rng];
}

function closeMe() {
  showOpts.value = false;
  emit("close");
}
</script>

<style></style>
